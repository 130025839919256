import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import BackgroundImage from "../images/hero-top.svg"
import Header, {
  NavLink,
  NavLinks,
  Tel,
  LogoLink,
  DesktopNavLinks,
  MobileNavLinksContainer,
} from "./Header.jsx"

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw` text-black`}
  }
  ${MobileNavLinksContainer} {
    ${tw` px-4`}
  }
`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-11/12 `}
  background-image: url(${BackgroundImage});
`
const HeroContainer = tw.div`relative sm:px-8  `
const TwoColumn = tw.div` flex justify-between items-center flex-col md:flex-row`
const LeftColumn = tw.div``
const RightColumn = tw.div`text-center lg:text-left w-full lg:w-3/5 mt-16 lg:mt-0 lg:pl-8`
const Heading = tw.h1`text-4xl sm:text-4xl md:text-5xl lg:text-6xl font-black leading-tight mt-24 lg:mt-96 `
const SubHeading = tw.div`text-base font-medium my-8 mx-4`
const ButtonsContainer = tw.div`flex flex-col lg:flex-row `
const Button = tw(
  Link
)`mb-8  mx-2 py-4 px-8 font-bold bg-primary-500 text-gray-100 rounded-full tracking-wider text-lg hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink to="/ai/">サクッとAI</NavLink>
      <NavLink to="/cloud/">サクッとクラウド</NavLink>
      <NavLink to="/contact/">お問い合わせ</NavLink>
      <Tel href="tel:0364279037">☎︎ 03-6427-9037</Tel>
    </NavLinks>,
  ]

  return (
    <Container>
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn></LeftColumn>
          <RightColumn>
            <Heading>
              「サクッとAI」と
              <br />
              「サクッとクラウド」で
              <br />
              DXをスマートに
              <br />
            </Heading>
            <SubHeading>
              サクッと簡単、高品質。
              <br />
              AI導入、クラウド移行、IoT開発をできるところから始めましょう。
              <br />
              DX推進の第一歩を支援いたします。
            </SubHeading>
            <ButtonsContainer>
              <Button to="/contact/">資料請求、お問い合わせはこちら</Button>
              <Button to="/consultai/">AI無料相談会実施中！</Button>
              <Button to="/consultcloud/">AWS無料相談会実施中！</Button>
            </ButtonsContainer>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  )
}
