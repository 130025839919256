import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import { StaticImage } from "gatsby-plugin-image"
import { Container as ContainerBase } from "./misc/Layouts.jsx"
import { Link } from "gatsby"

const Container = tw(ContainerBase)` -mx-8 -mb-8 h-9/12`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-6/12 mt-6 flex-shrink-0 h-80 md:h-auto`
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 `,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Heading = tw(
  SectionHeading
)`mt-2 sm:mt-4 font-black text-left text-4xl 2xl:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 mb-6 mx-8 lg:mx-0 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const ActionsContainer = tw.div`flex flex-col lg:flex-row `
const Actions = tw(
  Link
)`text-center text-base md:text-base rounded-full px-8 py-3 mx-2 mb-8  font-bold  bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

export default ({
  heading = <>AI、AWSスペシャリストがサポートします。</>,
  description = (
    <>
      Rossoには、多くの有識者が在籍しております。オンラインでも対面でも、お客様の要望を丁寧に伺い、最適なご提案を行います。
      <br />
      多彩な経験と知識を惜しみなく提供し、プロジェクト成功まで伴走いたします。
    </>
  ),
  textOnLeft = true,
}) => {
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <ActionsContainer>
              <Actions to="/contact/">資料請求、お問い合わせはこちら</Actions>
              <Actions to="/consultai/">AI無料相談会実施中！</Actions>
              <Actions to="/consultcloud/">AWS無料相談会実施中！</Actions>
            </ActionsContainer>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <StaticImage src="../images/mainfeature2.svg" />
        </ImageColumn>
      </TwoColumn>
    </Container>
  )
}
