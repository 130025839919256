import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import { Container as ContainerBase } from "./misc/Layouts.jsx"
import { Link } from "gatsby"
import ImageAI from "../images/ai-page.jpg"
import ImageCloud from "../images/cloud-page.jpg"
import feather from "./misc/feather.jsx"

const Container = tw(ContainerBase)`-mx-4 my-16 lg:my-32 h-9/12`

const HeaderContainer = tw.div`w-full py-6`
const Title = tw(SectionHeading)`w-full text-3xl sm:text-4xl lg:text-5xl`
const PlansContainer = tw.div`justify-center items-center md:items-start `

const TwoColumn = tw.div`flex flex-col lg:flex-row justify-between   my-8  bg-gray-100 rounded border-2`
const Column = tw.div`w-full   `
const ImageColumn = tw(Column)`w-full lg:w-1/2 `
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover py-24 bg-no-repeat bg-center h-full `,
])
const TextColumn = styled(Column)(props => [
  tw`lg:w-1/2 my-6 md:my-12 lg:m-12 `,
  props.textOnLeft ? tw` md:order-last lg:order-first ` : tw` md:order-last`,
])
const TextContent = tw.div` text-center xl:text-left my-8`
const Heading = tw(
  SectionHeading
)`font-black text-3xl sm:text-4xl lg:text-5xl text-left `
const Description = tw.p`mt-4 text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Button = tw(
  Link
)`py-4 px-8 font-bold bg-primary-500 text-gray-100 rounded-full tracking-wider w-full text-sm lg:text-lg transform hover:shadow-xl  hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`

const FeatureList = tw.ul`my-8 leading-loose`
const Feature = tw.li`flex items-center`
const FeatureText = tw.p`ml-2 font-medium text-gray-700 text-sm sm:text-lg`

export default ({ title = "Service", imageInsideDiv = true }) => {
  const services = [
    {
      image: ImageAI,
      name: (
        <>
          ｢サクッとAI｣で
          <br />
          DX推進をスマートに
        </>
      ),
      description: (
        <>
          AIを用いた画像認識/言語処理で業務効率化、データを分析して合理的な判断を。
          <br />
          RossoのサクッとAIで手軽に始めましょう！
          <br />
          Rossoではご相談段階からAIスペシャリストが担当し、DX推進の第一歩をサポートいたします。
        </>
      ),
      link: "/ai/",
      features: [
        "最短1ヶ月で「サクッと」AIシステムを実装",
        "「サクッと」高品質なAIシステムを提供",
        "提案からシステム実装まで「サクッと」対応",
      ],
      textOnLeft: true,
    },
    {
      image: ImageCloud,
      name: (
        <>
          ｢サクッとクラウド｣で
          <br />
          DX推進をスマートに
        </>
      ),
      description: (
        <>
          サーバレス、自動化、コスト削減など、お客様のニーズに合わせたプランをスピーディーにご提案。
          <br />
          AWS認証取得実績多数のRossoが誇る、クラウドスペシャリストがDX推進の第一歩をサポートいたします。
          <br />
        </>
      ),
      link: "/cloud/",
      features: [
        "「サクッと」最短1週間でクラウド基盤の提案",
        "高品質なクラウドシステムを「サクッと」提供",
        "ご要望に合わせたアーキテクチャの提案・構築",
      ],
      textOnLeft: false,
    },
  ]

  return (
    <Container>
      <HeaderContainer>
        <Title>{title}</Title>
      </HeaderContainer>
      <PlansContainer>
        {services.map(service => (
          <TwoColumn>
            <ImageColumn>
              <Image imageSrc={service.image} />
            </ImageColumn>
            <TextColumn textOnLeft={service.textOnLeft}>
              <TextContent>
                <Heading>{service.name}</Heading>
                <Description>{service.description}</Description>
              </TextContent>
              <FeatureList>
                {service.features.map((feature, index) => (
                  <Feature key={index}>
                    {feather("check-circle", ["30", "30"])}
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
              <Button to={service.link}>詳細はこちら</Button>
            </TextColumn>
          </TwoColumn>
        ))}
      </PlansContainer>
    </Container>
  )
}
