import "tailwindcss/dist/base.css"
import React from "react"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"

import Hero from "../components/Hero.jsx"
import Services from "../components/Services.jsx"
import MainFeature1 from "../components/MainFeature1.jsx"
import MainFeature2 from "../components/MainFeature2.jsx"
import MainFeature3 from "../components/MainFeature3.jsx"
import News from "../components/News.jsx"
import Contact from "../components/Contact.jsx"
import Footer from "../components/Footer.jsx"
import SEO from "../components/misc/SEO.jsx"

const App = () => {
  return (
    <AnimationRevealPage>
      <SEO title="AIとクラウドの導入をもっと手軽に！｜サクッとAI、サクッとクラウド" />
      <Hero />
      <Services />
      <MainFeature1 />
      <MainFeature2 />
      <MainFeature3 />
      <Contact />
      <News />
      <Footer />
    </AnimationRevealPage>
  )
}

export default App
