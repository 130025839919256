import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import UseCaseAI from "../images/case-ai-1.jpg"
import UseCaseCloud from "../images/case-cloud-1.jpg"
import { Container as ContainerBase } from "./misc/Layouts.jsx"
import { Link } from "gatsby"

const Container = tw(
  ContainerBase
)`bg-gray-900 text-gray-100  -mx-8 -mb-8 h-9/12`
const Content = tw.div`max-w-screen-xl mx-auto py-12 `

const ThreeColumn = tw.div`flex flex-wrap`
const Column = tw.div`xl:mr-12 xl:last:mr-0`
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`,
])
const CardColumn = tw(
  Column
)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed  mt-8  mx-8 lg:mx-0 `

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs rounded border-2`
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`,
])

const CardText = tw.div`py-4`

const CardHeader = tw.div`flex flex-col justify-between items-center`
const CardSolution = tw(Link)` font-bold text-lg`
const CardService = tw.div`font-semibold text-sm text-gray-600`

const Description = tw.div`text-base text-center my-4 mx-8 lg:mx-0 font-bold h-20 `

const CardAction = tw(
  Link
)` px-8 py-3  font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

export default ({
  headingHtmlComponent = (
    <>AI、AWSの豊富な実績から安心なサービスを提供します。</>
  ),
  description = (
    <>
      2006年の創業以来、様々な業界、業種のお客様とともに歩んできたRosso。
      <br />
      小規模から大規模プロジェクトまで、対応実績が豊富にあり、企業規模問わず安心して選んでいただいております。
      <br />
      相談から、開発、導入、運用まで幅広くサービスを提供しているRossoの導入事例をご紹介します。
    </>
  ),
  textOnLeft = false,
}) => {
  const cards = [
    {
      imageSrc: UseCaseAI,
      solution: "コーヒー豆の不良検知",
      solutionlink: "/ai-1/",
      service: "サクッとAI",
      title:
        "手軽にコーヒー豆の品質検査ができるシステムを開発しました。小規模生産業者のブランド価値を守ります。",
      cardlinktext: "他の事例はこちら",
      cardlink: "/ai/",
    },
    {
      imageSrc: UseCaseCloud,
      solution: "センサーデータ基盤構築",
      solutionlink: "/cloud-1/",
      service: "サクッとクラウド",
      title:
        "各拠点にセンサーを設置し、収集したデータを分析のために可視化を行うシステムをサーバレスで構築しました。",
      cardlinktext: "他の事例はこちら",
      cardlink: "/cloud/",
    },
  ]
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardSolution to={card.solutionlink}>
                      {card.solution}
                    </CardSolution>
                    <CardService>{card.service}</CardService>
                    <Description>{card.title}</Description>
                    <CardAction to={card.cardlink}>
                      {card.cardlinktext}
                    </CardAction>
                  </CardHeader>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  )
}
